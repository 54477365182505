<template>
    <div class="drafts-list">
        <div class="container">
            <div class="drafts-list-title">
                <div class="back-button" @click="$emit('toggle-drafts-list', false)">
                    <img
                        src="/img/left-chevron.e4c5de79.svg"
                        width="10"
                        alt="Back"
                    >
                </div>
                <h1>Drafts</h1>
            </div>
            <memod-list :list-endpoint="userDraftsEndpoint" @delete-memo="(memoId) => $emit('delete-memo', memoId)" />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "DraftsList",
    components: {
        MemodList: () => import(/* webpackChunkName: "memod-list" */ "@/components/organisms/memod-list.vue")
    },
    data() {
        return {
            userDraftsEndpoint: `/users/${this.userId}/feeds/drafts?`
        };
    },
    computed: {
        ...mapState({
            userId: state => state.User.data.id
        })
    }
}
</script>

<style lang="scss" scoped>
.drafts-list {
    padding-top: 60px;
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: #0F0F10;
    z-index: 5;

    &-title {
        display: flex;
        align-items: center;

        .back-button {
            cursor: pointer;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
</style>
